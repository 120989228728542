import React from 'react'
import { Breadcrumb, BreadcrumbNav, PageType } from './style'

const PostNavigation = ({ theme, blogPagePath }: any) => {
  return (
    <BreadcrumbNav>
      <Breadcrumb>
        <li>
          <PageType>
            <a className="blog" href={`${blogPagePath}`}>
              Blog
            </a>
          </PageType>
        </li>

        <li>
          <PageType>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>
            <a className="current" href="#" aria-current="page">
              {theme}
            </a>
          </PageType>
        </li>
      </Breadcrumb>
    </BreadcrumbNav>
  )
}

export default PostNavigation
