import styled from '@emotion/styled'
import tw from 'twin.macro'

export const PostStyle = styled.div`
  ${tw`
    relative
    py-16
    overflow-hidden
  `}
`

export const PostBackgroundGradient = styled.div`
  position: absolute;
  height: 728px;
  width: 100%;
  left: 0px;
  border-radius: 0px;
  background: radial-gradient(
    199.91% 208.79% at 23.58% -91.16%,
    #03cdfe 0%,
    #317ce9 52.29%,
    rgba(233, 49, 93, 0) 95.83%
  );
  opacity: 0.05;
`

export const PostWrapper = styled.div`
  ${tw`
    relative
    max-w-3xl
    mx-auto
  `}
`
