import styled from '@emotion/styled'
import tw from 'twin.macro'

export const BreadcrumbNav = styled.nav`
  ${tw`
    flex
    mb-16
  `}
`

export const Breadcrumb = styled.ol`
  ${tw`
    flex
    items-center
    space-x-4
  `}
`

export const PageType = styled.div`
  ${tw`
    flex items-center
  `}
  svg {
    ${tw`
      flex-shrink-0 h-5 w-5 text-gray-500
    `}
  }
  a {
    ${tw`
      text-sm font-medium text-indigo-600
    `}
    &.current {
      ${tw`
        ml-3 
        pointer-events-none
      `}
    }
    &.blog {
      ${tw`
        hover:text-indigo-500
      `}
    }
  }
`
