import React from 'react'
import Container from '../../elements/container'
import PostBody from './post_body'
import PostHero from './post_hero'
import PostNavigation from './post_navigation'
import { PostBackgroundGradient, PostStyle, PostWrapper } from './style'

const BlogPost = ({ postText, title, description, image, author, theme, blogPagePath }: any) => {
  return (
    <>
      <PostBackgroundGradient />
      <PostStyle>
        <Container>
          <PostWrapper>
            <PostNavigation theme={theme} blogPagePath={blogPagePath} />
            <PostHero
              title={title}
              description={description}
              image={image}
              author={author}
              theme={theme}
            />
            <PostBody postText={postText} />
          </PostWrapper>
        </Container>
      </PostStyle>
    </>
  )
}

export default BlogPost
