import React from 'react'
import Layout from '../../components/layout'
import BlogPost from '../../components/blog/blog_post'
import { RichText } from 'prismic-reactjs'

const PostBlog = ({ pageContext: { currentPost, localePath, blogPagePath } }: any) => {
  return (
    <Layout
      helmetTitle={currentPost.data.title[0].text}
      helmetDescription={currentPost.data.meta_description}
      helmetImage={currentPost.data.meta_image.url}
      helmetUrl={`https://www.cryptr.co${localePath}`}
    >
      <BlogPost
        title={currentPost.data.title[0].text}
        image={currentPost.data.image.url}
        description={currentPost.data.description}
        postText={RichText.render(currentPost.data.text)}
        author={currentPost.data.body[0].primary}
        theme={currentPost.data.theme}
        blogPagePath={blogPagePath}
      />
    </Layout>
  )
}

export default PostBlog
