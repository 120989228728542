import React from 'react'
import {
  Author,
  Intro,
  PostHeroStyle,
  PostHeroTitle,
  PostImage,
  PostIntro,
  PostIntroWrapper,
} from './style'

const PostHero = ({ title, description, image, author }: any) => {
  return (
    <PostHeroStyle>
      <PostHeroTitle>{title}</PostHeroTitle>
      <PostImage>
        <img src={image} alt={title} />
      </PostImage>
      <PostIntroWrapper>
        <PostIntro>
          <Author>
            <img src={author.photo.url} alt={author.name} />
            <div>
              {author.linkedin ? (
                <a href={author.linkedin} target="_blank">
                  <h3>{author.name}</h3>
                </a>
              ) : (
                <h3>{author.name}</h3>
              )}
              <div>
                <p>{author.position}</p>
              </div>
            </div>
          </Author>
          <Intro>
            <p>{description}</p>
          </Intro>
        </PostIntro>
      </PostIntroWrapper>
    </PostHeroStyle>
  )
}

export default PostHero
