import styled from '@emotion/styled'
import tw from 'twin.macro'

export const PostBodyStyle = styled.div`
  ${tw`
    text-gray-500
    mx-auto
    leading-8
    text-purple-900
  `}
  h1 {
    ${tw`
      text-2xl
      sm:text-4xl
      font-bold
      mb-8
      mt-12
    `}
  }
  h2 {
    ${tw`
      text-2xl
      sm:text-3xl
      font-bold
      mb-8
      mt-12
    `}
  }
  h3 {
    ${tw`
      text-xl
      sm:text-2xl
      font-bold
      mb-8
      mt-12
    `}
  }
  h4 {
    ${tw`
      text-lg
      sm:text-xl
      font-bold
      mb-8
      mt-12
    `}
  }
  h5 {
    ${tw`
      text-base
      sm:text-lg
      font-bold
      mb-8
      mt-12
    `}
  }
  h6 {
    ${tw`
      text-sm
      sm:text-base
      font-bold
      mb-8
      mt-12
    `}
  }
  a {
    ${tw`
      text-indigo-600
      hover:text-indigo-500
      cursor-pointer
    `}
  }
  img {
    ${tw`
      w-full
      rounded-lg
    `}
  }
  p {
    ${tw`
      my-6
      text-sm
      sm:text-base
    `}
  }
  ul {
    ${tw`
      pl-10
      my-4
      list-disc
    `}
  }
  ol {
    ${tw`
      pl-10
      my-4
      list-decimal
    `}
  }
  li::marker {
    ${tw`
      text-gray-400
      font-medium
    `}
  }
  pre {
    ${tw`
      mt-3
      bg-blue-800
      text-indigo-100
      rounded-lg
      p-6
    `}
  }
`
