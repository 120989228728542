import styled from '@emotion/styled'
import tw from 'twin.macro'

export const PostHeroStyle = styled.div`
  ${tw`
    text-lg 
    mx-auto 
    mb-6
  `}
`
export const PostContentIntro = styled.div`
  ${tw`
  `}
`

export const PostImage = styled.div`
  img {
    ${tw`
      rounded-lg
      mb-12
    `}
  }
`

export const PostIntroWrapper = styled.div`
  ${tw`
    flex
    flex-col
    sm:flex-row
  `}
`
export const PostIntro = styled(PostIntroWrapper)``

export const Intro = styled.div`
  ${tw`
    w-full
    sm:w-2/3
    sm:ml-6
  `}
  p {
    ${tw`
      font-medium
      text-base
      sm:text-xl
      text-purple-900
    `}
  }
`

export const Author = styled.div`
  ${tw`
    flex
    w-full
    sm:w-1/3
    pb-3
    sm:pb-0
    items-start
    space-x-3
  `}
  img {
    ${tw`
      w-12
      h-12
      rounded-full
      lg:w-16
      lg:h-16
    `}
  }
  div {
    ${tw`
      w-full
      font-medium
      leading-6
      pb-3
    `}
    h3 {
      ${tw`
        text-lg
        font-medium
        text-purple-300
        hover:text-purple-800
        cursor-pointer
        pb-1
      `}
    }
    p {
      ${tw`  
        text-purple-300
        text-sm
        leading-5
      `}
    }
    span {
      ${tw`
        inline-flex
        items-center
        px-3
        py-0
        rounded-full
        text-xs
        uppercase
        font-medium
        bg-indigo-600
        text-white
      `}
    }
  }
`

export const PostHeroCaption = styled.p`
  ${tw`
    text-base
    text-left
    leading-6
    text-indigo-600
    font-semibold
    tracking-wide
    uppercase
  `}
`

export const PostHeroTitle = styled.h1`
  ${tw`
    mt-2
    mb-8
    text-2xl
    text-left
    leading-10
    font-extrabold
    tracking-tight
    text-purple-900
    sm:text-5xl
    sm:leading-snug
  `}
`

export const PostHeroDescription = styled.p`
  ${tw`
    text-xl
    text-gray-500
    leading-8
  `}
`
